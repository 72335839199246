/*
 * SSO env for Dev, Test, Stage, Prod all use .garmin.com domain for cookies.  CDC uses .garmin.cn for the cookie domain.
 * The env connectus.garmin.cn uses Prod (it should have .garmin.com as the cookie domain).
 * @param {String} hostname - The current host
 * @returns {String} the correct SSO cookie domain
 */
export const getSSOCookieDomain = (hostname) => {
    if (hostname.indexOf('connect.garmin.cn') !== -1) {
        return '.garmin.cn';
    }
    return '.garmin.com';
};

/*
 * getSSOHostname - generate the correct SSO url depending on whether we are in stage, test, prod, or china
 * @param {String} hostname - The current host
 * @returns {String} the correct SSO URL
 */
export const getSSOHostname = (hostname) => {
    if (hostname.indexOf('connect.garmin.com') > 0 || hostname.indexOf('connectus.garmin.cn') > 0) {
        return 'sso.garmin.com'; // PROD
    } else if (hostname.indexOf('connectstg.garmin.com') > 0) {
        return 'ssostg.garmin.com'; // STAGE
    } else if (hostname.indexOf('connect.garmin.cn') > 0) {
        return 'sso.garmin.cn'; // China
    } else if (hostname.indexOf('connectpink.dev.garmin.com') > 0) {
        return 'ssotest-china.garmin.com'; // Test China
    } else {
        return 'ssotest.garmin.com';
    }
};

/*
 * getSSOClientId - get the correct SSO ClientId to use based on the current env host
 * @param {String} hostname - The current host
 * @returns {String} the correct SSO ClientId
 */
export const getSSOClientId = (hostname) => {
    if (hostname.indexOf('connectus.garmin.cn') > 0) {
        return 'GarminConnectUSCN';
    } else if (hostname.indexOf('connectpink.dev.garmin.com') > 0) {
        return 'GarminConnectPink';
    } else {
        return 'GarminConnect';
    }
};
